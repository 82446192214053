<template>
    <div>
    <autogestion-Paso-Tres class="landscape"></autogestion-Paso-Tres>  
    <v-row>
      <v-col cols="12" style="height: 125px;"></v-col>
    </v-row>
      <v-container style="margin-top:75px">
        <v-row>
          <v-col cols="12">    
              <v-card height="200px">
                  <v-card-text>
                    <v-simple-table class="mt-4">
                      <thead>
                        <tr class="headerPileta">
                            <th style="width:300px">
                             NOMBRE
                            </th>   
                            <th style="width:276px">
                              APELLIDO
                            </th>   
                            <th style="width:150px">
                              SEDE
                            </th>   
                            <th style="width:150px">
                              Nº SOCIO
                            </th>                           
                          </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <strong>{{ registro.nombre.toUpperCase() }}</strong>                            
                          </td>
                          <td>
                            <strong>{{ registro.apellido.toUpperCase() }}</strong>                            
                          </td>
                          <td>
                            <strong>{{ registro.sede.toUpperCase() }}</strong>                            
                          </td>
                          <td>
                            <strong>{{ registro.nsocio }}</strong>                            
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
              </v-card>
          </v-col>           
        </v-row>
        <v-row justify="center" style="margin-top:100px">
            <botonInicio style="display: inline;" class="ml-4 mr-4"/> 
            <botonVolver style="display: inline;" class="ml-4 mr-4"/>           
            <botonBoleta style="display: inline;" @Click="getPdf" class="ml-4 mr-4" />
        </v-row>
        <pdfDialog v-model="showPdfDialog" ref="pdfDialog" />
      </v-container>
    </div>
</template>

<script>  
  export default {   
    components: {
      pdfDialog: () => import('../components/pdfDialog.vue'),
      botonBoleta: () => import('../components/botonBoleta.vue'),
      botonInicio: () => import('../components/botonInicio.vue'),
      autogestionPasoTres: () => import('../components/autogestionPasoTres.vue'),

    }, 
    props: {
      tributo: {
        type: String,
        required: true,
      },
      cuenta: {
        type: String,
        required: true,
      },
    },     
    data () { return {    
      user_id : localStorage.getItem('userId'),
      registro: {},          
      url_pdf: '',
      showPdfDialog: false,
    }},
    mounted: async function () {
      await this.getCuotas()
    },       
    methods: {
      getCuotas: async function () {
        await this.$store.dispatch('axiosMod/getData', { url: '/SAT_WS/rest/cuentas/existe/' + this.cuenta + '/PILETAS?td=DNI' }).then((res) => { 
          this.registro = res
        }).catch(() => {
            this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"])      
        })            
      },
      getPdf: async function () {     
          await this.$store.dispatch('axiosMod/getData', { url: '/SAT_WS/rest/recibo/piletas/' + this.cuenta + '/DNI/usuario/' + this.user_id + '/totem' }).then((response) => {
            this.url_pdf = response.url
            this.showPdfDialog = true
            this.$refs.pdfDialog.setForm(this.url_pdf)      
          }).catch(() => {
              this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"])
           })     
      },
    },
  }
</script>

<style scoped>

.theme--light .v-data-table .headerPileta {
    background-color: rgb(153, 153, 153) !important;
}

 thead > tr > th {
  font-size: 16px !important;
  color: #FFF !important;
}

tbody > tr > td > strong {
  font-size: 14px !important;
  color: #171717 !important
}

</style>
